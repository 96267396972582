<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="submitData">
        <el-form label-position="right" label-width="150px" :rules="rules" :model="formInline" :ref="'form'">
            <div style="border: 1px solid #C0CCDA;border-radius: 4px;padding:15px;">
            <h2 class="title">
              <div class="title_icon">
              </div>
              <span>模板配置</span>
            </h2>
            <el-row>
              <el-col span="12">
                <el-form-item label="活动头图:" prop="headPhoto" required>
                  <el-upload
                    class="avatar-uploader"
                    name="image"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :headers="uploadHeader"
                    action="/acb/2.0/systems/loginUser/upload"
                  >
                    <img v-if="formInline.headPhoto" :src="formInline.headPhoto" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item label="背景颜色:" prop="backgroundColor">
                  <el-color-picker v-model="formInline.backgroundColor"></el-color-picker>
                  <span>{{formInline.backgroundColor}}</span>
                </el-form-item>
                <el-form-item label="文字颜色:" prop="fontColor">
                  <el-color-picker v-model="formInline.fontColor"></el-color-picker>
                  <span>{{formInline.fontColor}}</span>
                </el-form-item>
                <el-form-item label="活动说明:" prop="content" style="width: 600px">
                  <template>
                    <el-input
                      type="textarea"
                      :rows="6"
                      placeholder="请输入活动说明"
                      v-model="formInline.content"
                      maxlength="200"
                      show-word-limit
                    />
                  </template>
                </el-form-item>
                <el-form-item label="是否允许用户分享:" prop="share">
                  <el-radio v-model="formInline.share" :label="0">是</el-radio>
                  <el-radio v-model="formInline.share" :label="1">否</el-radio>
                </el-form-item>
              </el-col>
              <el-col span="12">
                <div
                  class="preview"
                  :style="{ backgroundColor: formInline.backgroundColor }"
                >
                  <div class="head-photo">
                    <img v-if="this.headPhoto" :src="this.headPhoto">
                  </div>
                  <div class="containner" :style="{ color: formInline.fontColor, backgroundColor: formInline.backgroundColor }">
                    <div class="please-input">请输入手机号领取优惠券</div>
                    <div style="display: flex">
                      <input placeholder="请输入手机号" class="phone-input" style="width: 100%"/>
                      <div class="phone-button">获取验证码</div>
                    </div>
                    <input placeholder="请输入短信验证码" class="phone-input"/>
                    <button class="receive" disabled>立即领取</button>
                    <div class="activity-description">
                      <div class="description-title">活动说明</div>
                      <div class="description-content">
                        {{ formInline.content }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div v-if="formInline.share === 0">
              <h2 class="title">
                <div class="title_icon">
                </div>
                <span>分享配置</span>
              </h2>
              <el-form-item label="分享标题:" prop="shareTitle">
                <el-input v-model="formInline.shareTitle" placeholder="请输入分享标题" maxlength="30" style="width: 500px"/>
              </el-form-item>
              <el-form-item label="分享文案:" prop="shareContent">
                <el-input v-model="formInline.shareContent" placeholder="请输入分享文案" maxlength="50" style="width: 500px"/>
              </el-form-item>
              <el-form-item label="分享图片:" prop="sharePhoto" required>
                <el-upload
                  class="avatar-uploader"
                  name="image"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess1"
                  :before-upload="beforeAvatarUpload"
                  :headers="uploadHeader"
                  action="/acb/2.0/systems/loginUser/upload"
                >
                  <img v-if="formInline.sharePhoto" :src="formInline.sharePhoto" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div style="text-align: center;margin-top:20px;">
          <el-button type="primary" @click="submitData" style="width: 88px;">{{ $t('button.preservation') }}</el-button>
          <el-button @click="$router.go(-1)" style="width: 88px;">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Yconfiguration',
    data () {
      return {
        headPhoto: '',
        uploadHeader: {
          "Session-Id": sessionStorage.token
        },
        couponActivityId: this.$route.query.couponActivityId,
        formInline: {
          backgroundColor: '',
          fontColor: '',
          content: '',
          share: 0,
          shareTitle: '',
          shareContent: '',
          headPhoto: '',
          sharePhoto: ''
        },
        isUpdate: true,
        rules: {
          backgroundColor: [
            { required: true, message: '请选择背景颜色', trigger: 'change' }
          ],
          fontColor: [
            { required: true, message: '请选择文字颜色', trigger: 'change' }
          ],
          content: [
            { required: true, message: '请输入活动说明', trigger: 'blur' }
          ],
          share: [
            { required: true, message: '请选择是否可分享', trigger: 'change' }
          ],
          shareTitle: [
            { required: true, message: '请输入分享标题', trigger: 'blur' }
          ],
          shareContent: [
            { required: true, message: '请输入分享文案', trigger: 'blur' }
          ],
          headPhoto: [
            { required: true, message: '请选择活动头图', trigger: 'change' }
          ],
          sharePhoto: [
            { required: true, message: '请选择分享图片', trigger: 'change' }
          ]
        },
        baseUrl: process.env.NODE_ENV === 'development' ? 'http://acb-pre.acs.aipark.com/' : 'http://acb.acs.aipark.com/'
      }
    },
    methods: {
      AvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      handleAvatarSuccess (res, file) {
        this.headPhoto = URL.createObjectURL(file.raw)
        this.formInline.headPhoto = res.value
      },
      handleAvatarSuccess1 (res, file) {
        this.sharePhoto = URL.createObjectURL(file.raw)
        this.formInline.sharePhoto = res.value
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      submitData () {
        this.$refs['form'].validate((valid) => {
          if (valid) {
            const tempData = {
              couponActivityId: this.couponActivityId,
              ...this.formInline
            }
            let url = ''
            if (this.isUpdate) {
              url = '/acb/2.0/couponActivity/updateTemplate'
            } else {
              url = '/acb/2.0/couponActivity/template'
            }
            const opt = {
              method: 'post',
              url,
              data: tempData,
              success: res => {
                this.$router.go(-1)
              }
            }
            this.$request(opt)
          }
        })
      },
      getDetailInfo () {
        const opt = {
          method: 'get',
          url: '/acb/2.0/couponActivity/templateGetByActivityId',
          data: { couponActivityId: this.couponActivityId },
          success: res => {
            if (res.value) {
              this.formInline = res.value
              this.headPhoto = res.value.headPhoto
            } else {
              this.isUpdate = false
            }
          }
        }
        this.$request(opt)
      }
    },
    mounted () {
      this.getDetailInfo()
    }
  }
</script><!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
  .breadcrumb
    height: 35px;
  .content
    background: #FFFFFF;
    overflow: hidden;
  .preview
    margin 0 auto
    width 375px
    height 667px
    border 1px solid #ddd
    border-radius 5px
    .head-photo
      height 160px
      img
        width 100%
        height 100%
    .containner
      padding 0 16px
      height calc( 667px - 160px )
      display flex
      flex-direction column
      .please-input
        font-size 15px
        margin-top 40px
      .phone-input
        margin-top 12px
        height 44px
        outline none
        border 1px solid rgba(204,204,204,1)
        padding 0 16px
        font-size 16px
        -webkit-appearance none
        background-color #fff
      .phone-button
        width 100px
        flex-shrink 0
        margin-top 12px
        text-align center
        height 46px
        line-height 46px
        border 1px solid #ccc
        border-left none
        box-sizing border-box
      .receive
        margin-top 32px
        height 50px
        width 100%
        color #fff
        background-color #FEA12B
        border-radius 10px
        border none
        outline none
        -webkit-appearance none
        font-size 17px
        cursor pointer
      .activity-description
        margin-top 75px
      .description-title
        font-size 15px
      .description-content
        font-size 12px
        margin 8px 0
        line-height 2em
        word-wrap: break-word;
</style>
<style>
  .tip{
    position: absolute;
    left: 46px;
    top: 113px;
    color: gray;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #0f6eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: visible;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #20a0ff;
  }
  .avatar-uploader .no{
    position:absolute;
    left:100%;
    margin-left:10px;
    bottom:0;
    display: block;
    width:50px;
    color:gray;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .map {
    position: absolute;
    right:-100px;
    top:0;
  }
  .amap-demo {
    height: 500px;
    width: 100%;
  }

  .prompt {
    background: white;
    width: 100px;
    height: 30px;
    text-align: center;
  }
  .search {
    float: left;
  }
  .lnglat {
    float: left;
    margin-left:10px;
  }
  .map_bottom{
    padding-top:20px;
  }
  .map_button{
    float:right;
  }
  .el-color-picker__mask{
    background-color: transparent;
    opacity: 1;
  }
</style>
